import permissions from './permissions'

const rules = {
  'FabricManagement': [permissions.readSageData, permissions.readJob, permissions.readDHU, permissions.readDispatch, permissions.readRack, permissions.readPallet],
  'DepartmentsTitle': [permissions.readSageData, permissions.readJob, permissions.readDHU, permissions.readDispatch, permissions.readRack, permissions.readPallet],
  'DeviceHandling': [permissions.deleteFabricBarcode, permissions.deleteJob],
  'FabricAnalytics': [permissions.createSageData],
  'RackmanageAnalytics': [permissions.createSageData],
  'ShrinkageAnalytics': [permissions.createSageData],
  'CuttingAnalytics': [permissions.readJob],
  'SewingAnalytics': [permissions.readDHU, permissions.readDispatch],
  'WarehouseAnalytics': [permissions.readRack, permissions.readPallet],
  'Finishing': [permissions.readFinishing, permissions.createFinishing],
  'ProductsTitle': [permissions.createParts, permissions.createOrders, permissions.createTechPack, permissions.readFabricBarcode, permissions.createFabricBarcode],
  'ManageOrders': [permissions.createParts, permissions.createOrders, permissions.createTechPack],
  'OcDetails': [permissions.readOcDetails],
  'PartsEntry': [permissions.createParts],
  'OrdersUpdate': [permissions.createOrders],
  'TechPackUpload': [permissions.createTechPack],
  'Barcodes': [permissions.readFabricBarcode, permissions.createFabricBarcode],
  'ZebraPrinter': [permissions.createZebraPrint],
  'OverView': [permissions.readOverView],
  'OcTracker': [permissions.readOcTracker],
  'JobworkOrder': [permissions.readJobwork, permissions.createJobwork],
  'ProductQr': [permissions.createProductQr],
  'MirAnalytics': [permissions.deleteFabricBarcode],
  'DhuAnalytics': [permissions.deleteJob],
  'Report': [permissions.readDHU, permissions.readDispatch],
}
export default rules