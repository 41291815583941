import React, { useState, useCallback, useEffect } from 'react'
import { Box, Grid, Tabs, Tab, Paper, LinearProgress, TextField } from '@mui/material'
import { SearchBar } from '../UI/Components'
import { format } from 'date-fns'
import useAuthenticationToken from '../Services/Authentication/useAuthenticationToken'
import {
  getLocationList, postRackBarcodeGenerate, getConsolidatedFabricDetails, getFabricDataBasedOnItemCode, getFabricDataBasedOnRack, getFabricDataBasedOnFabricBarcode, getFabricDataBasedOnSupplierBarcode
} from './api/apiFunctions'
import { AxiosError } from 'axios'
import SnackBarMessage from '../UI/Components/SnackBarMessage'
import StringValues from '../Providers/StringValues'
import { FlatButton, DatePicker } from '../UI/Components'
import { dateRangeType } from '../UI/types'
import globalEnums from '../UI/enum'
import { sortAlphaNumericArrayList } from '../utils'
import { locationDetailsType, PostRackGenerateBarcodeVariables, FabricDataInfo } from './types'



import {
  TabPanel, LocationDropdownMenu, ReprintRackQrLabel, RackFabricDropdownMenu, FabricConsolidateData, FabricDataForItemCodeAndRack, FabricDataForFabricBarcode, FabricDataForSupplierFabricBarcode
} from './components'



interface RachManageDashboardProps {
  componentHeader: (headerName: string) => void
}

enum DropdownMenuValues {
  location = 'Location',
  rackNumber = 'Rack Number',
  itemCode = 'Item Code',
  fabricBarcode = 'Fabric Barcode',
  supplierBarcode = 'Supplier Number',
  fabric = 'Fabric',
  wareHouseEntry = 'Warehouse Entry',
  wareHouseExit = 'Warehouse Exit'
}

const RachManageDashboard = (props: RachManageDashboardProps) => {
  useAuthenticationToken()
  props.componentHeader('Rack Management')
  const [isLoading, setIsLoading] = useState(false)
  const [tabState, setTabState] = useState(0)
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [snackBarOpen, setSnackBarOpen] = useState(false)
  const [dateRange, setDateRange] = useState<dateRangeType>()
  const [rackAllDetailsInsert, setRackAllDetailsInsert] = useState<PostRackGenerateBarcodeVariables[]>([])
  const [dataSubmitStatus, setDataSubmitStatus] = useState(false)

  const [ocSearchTerm, setOcSearchTerm] = useState<string>('')
  const [isDataLoading, setIsDataLoading] = useState(false)
  const [rackBarcode, setRackBarcode] = useState('')

  const [fabricSearchTerm, setFabricSearchTerm] = useState<string>('')
  const [fabricSearchResults, setFabricSearchResults] = useState<FabricDataInfo[]>([])
  const [fabricSearchResultsBasedOnBarcode, setFabricSearchResultsBasedOnBarcode] = useState<FabricDataInfo[]>([])
  const [fabricConsolidateData, setFabricConsolidateData] = useState<FabricDataInfo[]>([])
  // const [fabricTrackData, setFabricTrackData] = useState<FabricDataInfo[]>([])
  // const [selectedPalletDropDown, setSelectedPalletDropDown] = useState<string>(DropdownMenuValues.articleNumber)
  const [selectedFabricDropDown, setSelectedFabricDropDown] = useState<string>(DropdownMenuValues.itemCode)
  // const [selectedTrackerDropDown, setSeles, setSelectedTrackerEntryExitDropDown] = useState<string>(DropdownMenuValues.wareHouseEntry)
  const [isFabricSearchdataLoaded, setIsFabricSearchdataLoaded] = useState(false)

  useEffect(() => {
    handleFetchLocation()
    if (isFabricSearchdataLoaded === false) {
      fetchFabricConsolidateData()
      // handleContinueButtonClick()
    }
  }, [isFabricSearchdataLoaded])

  const handleFetchLocation = async () => {
    await new Promise(r => setTimeout(r, 500))
    fetchLocationList()
  }


  const fetchLocationList = async () => {
    if (isLoading) {
      return
    }
    try {
      setIsLoading(true)
      setDataSubmitStatus(false)
      const locationsList = await getLocationList()
      setLocationListResults(locationsList)
      setIsLoading(false)

    } catch (err) {
      if (err instanceof AxiosError || err instanceof Error) {
        setSnackBarOpen(true)
        setIsLoading(false)
        setErrorMessage(err.message)
      } else {
        throw err
      }
    }
  }



  /** Function responsible to return location based on location name 
 * @param {string} locationName The location name
 * @return {string} The location code
 */
  const getLocationCodeBasedOnLocationName = (locationName: string) => {
    const locationObject = locationListResults.find(locationObject => locationObject.locationName === locationName)
    if (locationObject === null || locationObject === undefined) {
      return ''
    }
    return locationObject.location
  }


  const [location, setLocation] = useState<string>(DropdownMenuValues.location)
  const [locationListResults, setLocationListResults] = useState<locationDetailsType[]>([])





  // // location 
  //   /**This function is responsible to handle location dropDown value
  //  * @param {string} value The selected location
  //  */
  //   const handleLocationDropdownMenuChange = (value: string) => {
  //     setLocation(value)
  //   }

  /**
   * This function is responsible to handle dropDown list for location
   */
  const locationDropdownList = () => {
    let locations: string[] = []

    if (locationListResults.length > 0) {
      locations = locationListResults.map((locationObject) => {
        const { locationName, locationType } = locationObject

        if (locationType === globalEnums.production) {
          return locationName
        }
        return ''
      }).filter((obj) => { return obj !== '' })
    }
    locations = sortAlphaNumericArrayList(locations)
    locations.unshift(DropdownMenuValues.location)
    return locations
  }



  /**This function is responsible to handle location dropDown value
 * @param {string} value The selected location
 */
  const handleLocationDropdownMenuChange = (value: string) => {
    setOcSearchTerm('')
    setLocation(value)
  }

  /**Handle snack Bar close 
   * @param {boolean} value The status of snack bar
   */
  const handleSnackBarClose = (value: boolean) => {
    setSnackBarOpen(value)
  }

  /**Submit Scanned label barcode */
  // const submitNewRackBarcode = () => {

  //   if (location === '' || location === null || location === undefined || location === DropdownMenuValues.location) {
  //     setDataSubmitStatus(false)
  //     setSnackBarOpen(true)
  //     setIsError(false)
  //     setErrorMessage(StringValues.locationMessage)
  //     return
  //   }

  //   // if (labelBarcodeData.length === 0){
  //   //   setDataSubmitStatus(false)
  //   //   setSnackBarOpen(true)
  //   //   setIsError(false)
  //   //   setErrorMessage(StringValues.scanLabelBarcodeLabelListValidationMessage)
  //   //   return
  //   // }

  //   // const barcodeList =  labelBarcodeData.map((item)=>{
  //   //   const {ocNumber, productCode, poNumber, shipTo, colourCode} = item
  //   //   return {ocNumber, productCode, poNumber, country: shipTo, colourCode, barcode: item.labelBarcode, quantity: item.quantity}
  //   // })
  //   //   .filter((value, index, self) => self.indexOf(value) === index)
  //   // submitScannedBarcodes(barcodeList)



  // }
  // this function is used for generating rack barcodes
  const submitNewRackBarcode = async () => {
    console.log("Generate the rack barcode")

    if (!rackBarcode) {
      setSnackBarOpen(true)
      setIsError(true)
      setErrorMessage(StringValues.emptyRackMessage)
      return
    }
    if (!location || location == "Location") {
      setSnackBarOpen(true)
      setIsError(true)
      setErrorMessage(StringValues.locationMessage)
      return
    }
    console.log("location", location, "rackBarcode", rackBarcode)
    setRackAllDetailsInsert([])
    try {
      setIsLoading(true)
      // Call your API function to post rack barcode data
      const success = await postRackBarcodeGenerate({
        rackBarcode: rackBarcode,
        rackLocation: location
      })
      console.log("success", success)
      if (success) {
        setSnackBarOpen(true)
        setIsError(false)
        setSuccessMessage('Rack barcode data posted successfully')
        // Optionally, you can clear the form fields after successful submission
        setLocation(location)
        setRackBarcode('')
      } else {
        setSnackBarOpen(true)
        setIsError(true)
        setErrorMessage('Error posting rack barcode data')
      }
    } catch (err) {
      if (err instanceof AxiosError || err instanceof Error) {
        setSnackBarOpen(true)
        setIsError(true)
        setErrorMessage(err.message)
      } else {
        throw err
      }
    } finally {
      setIsLoading(false)
    }
  }
  const handleRackBarcodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRackBarcode(event.target.value)
  }


  // ---------------- fabric barcode 

  /**API call to Fetch fabric data based on itemCode or rack number or fabric barcode */
  const fetchFabricDataBasedOnItemCodeOrRackNumberOrBarcode = useCallback(async () => {
    console.log("location2", location, "fabricSearchTerm2", fabricSearchTerm)
    if (isLoading) {
      return
    }
    setIsLoading(true)
    setIsDataLoading(true)
    setFabricSearchResults([])
    setFabricSearchResultsBasedOnBarcode([])

    try {
      if (selectedFabricDropDown === DropdownMenuValues.itemCode) {
        console.log("fabricSearchTerm, location", fabricSearchTerm, location)
        const fabricDataResult = await getFabricDataBasedOnItemCode(fabricSearchTerm, location)
        setFabricSearchResults(fabricDataResult)
      }
      if (selectedFabricDropDown === DropdownMenuValues.rackNumber) {
        const fabricDataResult = await getFabricDataBasedOnRack(fabricSearchTerm, location)
        setFabricSearchResults(fabricDataResult)
      }

      if (selectedFabricDropDown === DropdownMenuValues.fabricBarcode) {
        const fabricDataResult = await getFabricDataBasedOnFabricBarcode(fabricSearchTerm, location)
        setFabricSearchResultsBasedOnBarcode(fabricDataResult)
      }
      if (selectedFabricDropDown === DropdownMenuValues.supplierBarcode) {
        const fabricDataResult = await getFabricDataBasedOnSupplierBarcode(fabricSearchTerm, location)
        console.log("fabricDataResult", fabricDataResult)
        setFabricSearchResultsBasedOnBarcode(fabricDataResult)
      }

      setIsError(false)
      setIsDataLoading(false)
      setIsFabricSearchdataLoaded(true)
      setErrorMessage('')
    } catch (err) {
      if (err instanceof AxiosError || err instanceof Error) {
        setSnackBarOpen(true)
        setIsError(true)
        setIsDataLoading(false)
        setIsFabricSearchdataLoaded(true)
        setErrorMessage(err.message)
      } else {
        throw err
      }
    }
    setIsLoading(false)

  }, [fabricSearchTerm])

  /**API call to Fetch pallet and fabric consolidate data */
  const fetchFabricConsolidateData = useCallback(async () => {
    if (isLoading) {
      return
    }
    setIsLoading(true)
    setIsDataLoading(true)
    try {
      const fabricConsolidateDataResult = await getConsolidatedFabricDetails()
      setFabricConsolidateData(fabricConsolidateDataResult)
      setIsError(false)
      setIsDataLoading(false)
      setIsFabricSearchdataLoaded(false)

      setErrorMessage('')
    } catch (err) {
      if (err instanceof AxiosError || err instanceof Error) {
        setSnackBarOpen(true)
        setIsError(true)
        setIsDataLoading(false)
        setIsFabricSearchdataLoaded(false)
        setErrorMessage(err.message)
      } else {
        throw err
      }
    }
    setIsLoading(false)

  }, [FabricConsolidateData])
  // check

  /**This function is responsible to handle fabric dropDown value
   * @param {string} value The selected pallet option
   */
  const handleFabricDropdownMenuChange = (value: string) => {
    setFabricSearchResults([])
    setFabricSearchResultsBasedOnBarcode([])
    setFabricSearchTerm('')
    setIsError(false)
    setSelectedFabricDropDown(value)
  }


  /**Handle search bar finder button click  for fabric*/
  const handleSearchBarButtonClickForFabric = () => {
    console.log("location1", location, "rackBarcode1", rackBarcode)
    fetchFabricDataBasedOnItemCodeOrRackNumberOrBarcode()
  }



  /**Handle search bar change for fabric */
  const handleSearchBarChangeForFabric = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFabricSearchTerm(event.target.value)
  }



  /**
   * This function is responsible to handle dropDown value for fabric
   */
  const fabricDropdownList = () => {
    return ([DropdownMenuValues.itemCode, DropdownMenuValues.rackNumber, DropdownMenuValues.fabricBarcode, DropdownMenuValues.supplierBarcode])
  }



  return (
    <React.Fragment>
      {/* <SnackBarMessage message={errorMessage} successStatus={false} open={snackBarOpen} onClose={handleSnackBarClose} /> */}
      {isError ? (
        <SnackBarMessage
          message={errorMessage}
          successStatus={false}
          open={snackBarOpen}
          onClose={handleSnackBarClose}
        />
      ) : (
        <SnackBarMessage
          message={successMessage}
          successStatus={true}
          open={snackBarOpen}
          onClose={handleSnackBarClose}
        />
      )}

      <Grid container sx={{
        height: '100vh'
      }}>
        <Grid item xs={3}
          sx={{
            paddingTop: 2,
            paddingBottom: 2
          }}>
          <Box sx={{
            width: '100%',
            height: '100%',
            backgroundColor: 'surface.dark'
          }}>
            <Paper elevation={8} sx={{
              height: '100%'
            }}>
              {/* tabs */}
              <Box sx={{
                bgcolor: 'primary.card'
              }}>
                <Tabs value={tabState} onChange={(e: React.SyntheticEvent, newValue: number) => {
                  setTabState(newValue)
                  setIsError(false)
                }} textColor='secondary' TabIndicatorProps={{
                  style: {
                    backgroundColor: 'alpha.light'
                  }
                }}>
                  <Tab sx={{
                    color: 'alpha.light'
                  }} label="FABRIC" />

                  <Tab sx={{
                    color: 'alpha.light'
                  }} label="RACK QR-CODE" />

                  {/* <Tab sx={{
                    color: 'alpha.light'
                  }} label="TRACK" /> */}
                </Tabs>
              </Box>
              {/* tab content */}

              <Box sx={{
                paddingTop: 2
              }}>


                {/* <TabPanel value={tabState} index={0}>
          

                  <Box sx={{ paddingLeft: 3, paddingBottom: 2, paddingTop: 3 }}>
                    <LocationDropdownMenu onChange={handleLocationDropdownMenuChange} menuValues={locationDropdownList()} buttonText={location} />
                  </Box>

                  <Box sx={{ paddingLeft: 3, paddingBottom: 2, paddingTop: 2 }}>
                    <RackFabricDropdownMenu onChange={handleFabricDropdownMenuChange} menuValues={fabricDropdownList()} buttonText={selectedFabricDropDown}></RackFabricDropdownMenu>
                  </Box>
                  <Box sx={{ padding: 2 }}>
                    <SearchBar value={fabricSearchTerm} onChange={handleSearchBarChangeForFabric}
                      onButtonClick={handleSearchBarButtonClickForFabric} error={isError} errorMessage={errorMessage}
                      inputLabel={selectedFabricDropDown === DropdownMenuValues.itemCode ? StringValues.itemCodeLabel
                        : selectedFabricDropDown === DropdownMenuValues.rackNumber ? StringValues.rackLabel
                          : selectedFabricDropDown === DropdownMenuValues.fabricBarcode ? StringValues.fabricBarcodeLabel
                            : StringValues.supplierNumber}
                      width={'80%'} marginLeft={10} />
                  </Box>
            
                </TabPanel> */}


                <TabPanel value={tabState} index={0}>
                  <Box sx={{ paddingLeft: 3, paddingBottom: 2, paddingTop: 3 }}>
                    <LocationDropdownMenu onChange={handleLocationDropdownMenuChange} menuValues={locationDropdownList()} buttonText={location} />
                  </Box>

                  <Box sx={{ paddingLeft: 3, paddingBottom: 2, paddingTop: 2 }}>
                    <RackFabricDropdownMenu onChange={handleFabricDropdownMenuChange} menuValues={fabricDropdownList()} buttonText={selectedFabricDropDown}></RackFabricDropdownMenu>
                  </Box>

                  <Box sx={{ padding: 2 }}>
                    <TextField value={fabricSearchTerm} onChange={handleSearchBarChangeForFabric}
                      label={selectedFabricDropDown === DropdownMenuValues.itemCode ? StringValues.itemCodeLabel
                        : selectedFabricDropDown === DropdownMenuValues.rackNumber ? StringValues.rackLabel
                          : selectedFabricDropDown === DropdownMenuValues.fabricBarcode ? StringValues.fabricBarcodeLabel
                            : StringValues.supplierNumber} />
                  </Box>

                  <Box display="flex" justifyContent="center" alignItems="center" sx={{ paddingTop: 2 }}>
                    <FlatButton label='SUBMIT' onClick={handleSearchBarButtonClickForFabric} disableControlsStatus={false} />
                  </Box>

                </TabPanel>




                <TabPanel value={tabState} index={1}>

                  <Box sx={{ marginLeft: 1, paddingLeft: 1, paddingBottom: 1, paddingTop: 3 }}>
                    <TextField
                      type='string'
                      InputLabelProps={{
                        shrink: true
                      }}
                      variant="outlined"
                      size="small"
                      value={rackBarcode}
                      onChange={handleRackBarcodeChange}
                      sx={{ width: '70%', paddingRight: 3 }}
                      placeholder={StringValues.rackLabel}
                    />
                    <LocationDropdownMenu onChange={handleLocationDropdownMenuChange} menuValues={locationDropdownList()} buttonText={location} />

                  </Box>
                  {/* <Box sx={{ paddingLeft: 2, paddingBottom: 2, paddingTop: 3 }}>
                    <LocationDropdownMenu onChange={handleLocationDropdownMenuChange} menuValues={locationDropdownList()} buttonText={location} />
                  </Box> */}
                  <Box display="flex"
                    justifyContent="center"
                    alignItems="center" sx={{ paddingTop: 2 }}>
                    <FlatButton label='SAVE' onClick={submitNewRackBarcode} disableControlsStatus={false} />

                  </Box>
                </TabPanel>

                <TabPanel value={tabState} index={2}>
                  <TextField placeholder={"this is Tracking system"} />
                  {/* <Box sx={{ paddingLeft: 1, paddingBottom: 2 }}>
                    <RackDropdownMenu onChange={handleTrackerPalletFabricDropdownMenuChange} menuValues={trackerPalletFabricDropdownList()} buttonText={DropdownMenuValues.pallet}></RackDropdownMenu>
                  </Box>

                  <Box sx={{ paddingLeft: 1, paddingBottom: 3 }}>
                    <RackDropdownMenu onChange={handleTrackerEntryExitDropdownMenuChange} menuValues={trackerEntryExitDropdownList()} buttonText={DropdownMenuValues.wareHouseEntry}></RackDropdownMenu>
                  </Box>

                  <Box sx={{ paddingLeft: 1, paddingBottom: 2, paddingRight: 1 }}>
                    <DatePicker onChange={handleDateRangeChange} defaultDateRange={(value: dateRangeType) => setDateRange(value)} direction={'vertical'} />
                  </Box>

                  <Box display="flex"
                    justifyContent="center"
                    alignItems="center" sx={{ paddingTop: 3 }}>
                    <FlatButton label='CONTINUE' onClick={handleContinueButtonClick} disableControlsStatus={false} />
                  </Box> */}

                </TabPanel>


              </Box>

            </Paper>

          </Box>

        </Grid>


        <Grid item xs={9} sx={{
          paddingLeft: 2,
          paddingRight: 2,
          paddingTop: 2,
          paddingBottom: 2
        }}> {
            (isDataLoading) ? (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  height: '100vh',
                  position: 'fixed',
                  top: 0,
                  left: 250,
                  zIndex: 9999,
                }}
              >
                <LinearProgress sx={{ width: '30%' }}/>
              </Box>
            ) : (<Box sx={{
              width: '100%',
              height: '100%',
              bgcolor: 'primary.pane',
              backgroundColor: 'surface.dark'
            }}>
              {/* --------------- code to print barcode label ---------------- */}
              {/* <Box sx={{ paddingTop: 2, marginLeft: 1 }}>
                <ReprintRackQrLabel />
              </Box> */}
              {/* -------------------------------- */}
              {isError === true ? null :
                tabState === 1 ? (
                  // Logic for tabState === 1
                  <ReprintRackQrLabel />
          
                ) :
                  tabState === 0 ? (
                    // Logic for tabState === 0
                    fabricConsolidateData.length > 0 && fabricSearchResults.length === 0 && fabricSearchResultsBasedOnBarcode.length === 0
                      ? <FabricConsolidateData fabricConsolidateResults={fabricConsolidateData} />
                      : selectedFabricDropDown === DropdownMenuValues.itemCode && fabricSearchResults.length > 0
                        ? <FabricDataForItemCodeAndRack fabricForItemCodeAndRackResults={fabricSearchResults} isDataBasedOnItemCode={true} />
                        : selectedFabricDropDown === DropdownMenuValues.rackNumber && fabricSearchResults.length > 0
                          ? <FabricDataForItemCodeAndRack fabricForItemCodeAndRackResults={fabricSearchResults} isDataBasedOnItemCode={false} />
                          : selectedFabricDropDown === DropdownMenuValues.fabricBarcode && fabricSearchResultsBasedOnBarcode.length > 0
                            ? <FabricDataForFabricBarcode fabricForFabricBarcodeResults={fabricSearchResultsBasedOnBarcode} />
                            : selectedFabricDropDown === DropdownMenuValues.supplierBarcode && fabricSearchResultsBasedOnBarcode.length > 0
                              ? <FabricDataForSupplierFabricBarcode fabricForFabricBarcodeResults={fabricSearchResultsBasedOnBarcode} />
                              : null
                  ) :
                    null // Default case
              }

            </Box>)
          }


        </Grid>

        {/* <div>
          <TextField placeholder={"this is empty Rack management system"} />
          <TextField placeholder={"this is empty field"} />
        </div> */}

      </Grid>


    </React.Fragment>

  )
}

export default RachManageDashboard
