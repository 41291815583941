import React, { useState, useEffect } from 'react'
import { TextField, Typography, Box, TableContainer, Paper, Table, TableCell, DialogTitle, DialogContent, Dialog, TableHead, TableBody, TableRow, IconButton, DialogActions, Button } from '@mui/material'
import { WorkFlowDetails, workFlowSizeQuantities, SizeQuantitListWithId } from '../types'
import { nanoid } from 'nanoid'
import { AddButton, FlatButton } from '../../UI/Components'
import DeleteIcon from '@mui/icons-material/Delete'
import { deletePartsBundleOperation } from '../api/apiFunctions'

interface OrdersProps {
  workFlowDetails: WorkFlowDetails
  submitWorkFlow: (orderQuantitiesDetails: workFlowSizeQuantities[]) => void
  onDataChange?: () => void
}

function WorkFlow(props: OrdersProps) {
  const { workFlowDetails, submitWorkFlow, onDataChange } = props
  const [newRows, setNewRows] = useState<Set<string>>(new Set())
  const [emptyRowDialog, setEmptyRowDialog] = useState(false)
  const [deleteConfirmDialog, setDeleteConfirmDialog] = useState(false)
  const [selectedRowToDelete, setSelectedRowToDelete] = useState<{ id: string, operationUniqueCode: string } | null>(null)
  const [localWorkFlowDetails, setLocalWorkFlowDetails] = useState<WorkFlowDetails>(workFlowDetails)
  const [initialDataLoaded, setInitialDataLoaded] = useState(false)

  const generateUniqueCode = (index: number) => {
    return `IDW${nanoid(10)}`
  }

  const [sizeQuantityList, setSizeQuantityList] = useState<SizeQuantitListWithId[]>([
    {
      id: nanoid(4),
      operationUniqueCode: generateUniqueCode(0),
      partBundle: '',
      partOperation: '',
      partSam: '',
      operatorName: '',
      operationUserToken: ''
    }
  ])

  useEffect(() => {
    if (workFlowDetails && workFlowDetails.sizeQuantitiesList.length > 0 && !initialDataLoaded) {
      const sizeQuantityWithId = workFlowDetails.sizeQuantitiesList.map((object, index) => {
        return {
          id: nanoid(4),
          operationUniqueCode: object.operationUniqueCode || generateUniqueCode(index),
          partBundle: object.partBundle,
          partOperation: object.partOperation,
          partSam: object.partSam,
          operatorName: object.operatorName,
          operationUserToken: object.operationUserToken
        }
      })

      setSizeQuantityList(sizeQuantityWithId)
      setLocalWorkFlowDetails(workFlowDetails)
      setInitialDataLoaded(true)
      setNewRows(new Set())
    }
  }, [workFlowDetails, initialDataLoaded])

  const updateUniqueCodes = (list: SizeQuantitListWithId[]) => {
    return list.map((item, index) => ({
      ...item,
      operationUniqueCode: item.operationUniqueCode || generateUniqueCode(index)
    }))
  }

  const handleFieldChange = (field: keyof SizeQuantitListWithId, value: string, indexToChange: string) => {
    if (field === 'operationUniqueCode') { return }

    const updatedList = [...sizeQuantityList]
    const index = updatedList.findIndex((obj) => obj.id === indexToChange)
    updatedList[index][field] = value
    setSizeQuantityList(updatedList)

    const updatedSizeQuantitiesList = updatedList.map(({ operationUniqueCode, partBundle, partOperation, partSam, operatorName, operationUserToken }) => ({
      operationUniqueCode,
      partBundle,
      partOperation,
      partSam,
      operatorName,
      operationUserToken
    }))

    setLocalWorkFlowDetails(prev => ({
      ...prev,
      sizeQuantitiesList: updatedSizeQuantitiesList
    }))
  }

  const handleDeleteClick = (id: string, operationUniqueCode: string) => {
    setSelectedRowToDelete({ id, operationUniqueCode })
    setDeleteConfirmDialog(true)
  }

  const handleDeleteConfirm = async () => {
    if (!selectedRowToDelete) { return }

    if (sizeQuantityList.length === 1) {
      setDeleteConfirmDialog(false)
      return
    }

    const updatedList = sizeQuantityList.filter((row) => row.id !== selectedRowToDelete.id)
    const recodedList = updateUniqueCodes(updatedList)
    setSizeQuantityList(recodedList)

    const updatedSizeQuantitiesList = recodedList.map(({ operationUniqueCode, partBundle, partOperation, partSam, operatorName, operationUserToken }) => ({
      operationUniqueCode,
      partBundle,
      partOperation,
      partSam,
      operatorName,
      operationUserToken
    }))

    setLocalWorkFlowDetails(prev => ({
      ...prev,
      sizeQuantitiesList: updatedSizeQuantitiesList
    }))

    if (newRows.has(selectedRowToDelete.id)) {
      const updatedNewRows = new Set(newRows)
      updatedNewRows.delete(selectedRowToDelete.id)
      setNewRows(updatedNewRows)
    }

    setDeleteConfirmDialog(false)
    setSelectedRowToDelete(null)

    try {
      if (selectedRowToDelete.operationUniqueCode) {
        const success = await deletePartsBundleOperation(selectedRowToDelete.operationUniqueCode)
        if (success) {
          if (onDataChange) {
            onDataChange()
          }
          submitWorkFlow(updatedSizeQuantitiesList)
        } else {
          console.error('Failed to delete operation')
        }
      } else {
        if (onDataChange) {
          onDataChange()
        }
        submitWorkFlow(updatedSizeQuantitiesList)
      }
    } catch (error) {
      console.error('Error deleting operation:', error)
    }
  }

  const handleDeleteCancel = () => {
    setDeleteConfirmDialog(false)
    setSelectedRowToDelete(null)
  }

  const addSizeQuantityRow = () => {
    const newId = nanoid(4)
    const updatedList = [
      ...sizeQuantityList,
      {
        id: newId,
        operationUniqueCode: generateUniqueCode(sizeQuantityList.length),
        partBundle: '',
        partOperation: '',
        partSam: '',
        operatorName: '',
        operationUserToken: ''
      }
    ]
    setSizeQuantityList(updatedList)
    setNewRows(new Set([...newRows, newId]))
  }

  const isRowEmpty = (row: SizeQuantitListWithId) => {
    return !row.partBundle || !row.partOperation || !row.partSam || !row.operatorName || !row.operationUserToken
  }

  const submitOrderQuantities = () => {
    const hasEmptyRow = sizeQuantityList.some(row => isRowEmpty(row))

    if (hasEmptyRow) {
      setEmptyRowDialog(true)
      return
    }

    const validRowsData = sizeQuantityList
      .filter(row => row.operationUniqueCode)
      .map(({
        operationUniqueCode,
        partBundle,
        partOperation,
        partSam,
        operatorName,
        operationUserToken
      }) => ({
        operationUniqueCode,
        partBundle,
        partOperation,
        partSam,
        operatorName,
        operationUserToken
      }))

    if (validRowsData.length > 0) {
      submitWorkFlow(validRowsData)
      setNewRows(new Set())
    }
  }

  const handleEmptyRowDialogClose = () => {
    setEmptyRowDialog(false)
  }

  return (
    <React.Fragment>
      <Box sx={{ paddingTop: 2, width: '145%' }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow sx={{ backgroundColor: 'alpha.lightest' }}>
                <TableCell><Typography variant="subtitle1">SNo</Typography></TableCell>
                <TableCell><Typography variant="subtitle1">Unique Code</Typography></TableCell>
                <TableCell><Typography variant="subtitle1">Section</Typography></TableCell>
                <TableCell><Typography variant="subtitle1">Operation</Typography></TableCell>
                <TableCell><Typography variant="subtitle1">SAM</Typography></TableCell>
                <TableCell><Typography variant="subtitle1">Operator</Typography></TableCell>
                <TableCell><Typography variant="subtitle1">User Token</Typography></TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {sizeQuantityList.map((object, index) => (
                <TableRow
                  key={object.id}
                  sx={{ backgroundColor: newRows.has(object.id) ? 'rgba(144, 238, 144, 0.1)' : 'inherit' }}
                >
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    <TextField
                      variant="outlined"
                      size="small"
                      value={object.operationUniqueCode}
                      style={{ height: '30px', width: '120px' }}
                      InputProps={{ readOnly: true }}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      variant="outlined"
                      size="small"
                      value={object.partBundle}
                      style={{ height: '30px', width: '140px' }}
                      onChange={(e) => handleFieldChange('partBundle', e.target.value, object.id)}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      variant="outlined"
                      size="small"
                      value={object.partOperation}
                      style={{ height: '30px', width: '500px' }}
                      onChange={(e) => handleFieldChange('partOperation', e.target.value, object.id)}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      variant="outlined"
                      size="small"
                      value={object.partSam}
                      style={{ height: '30px', width: '100px' }}
                      onChange={(e) => handleFieldChange('partSam', e.target.value, object.id)}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      variant="outlined"
                      size="small"
                      value={object.operatorName}
                      style={{ height: '30px', width: '140px' }}
                      onChange={(e) => handleFieldChange('operatorName', e.target.value, object.id)}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      variant="outlined"
                      size="small"
                      value={object.operationUserToken}
                      style={{ height: '30px', width: '120px' }}
                      onChange={(e) => handleFieldChange('operationUserToken', e.target.value, object.id)}
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="delete"
                      size="large"
                      onClick={() => handleDeleteClick(object.id, object.operationUniqueCode)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Box display="flex" justifyContent="left" alignItems="center" sx={{ paddingTop: 3 }}>
        <AddButton label="ADD ROW" onClick={addSizeQuantityRow} />
      </Box>

      <Box display="flex" justifyContent="center" alignItems="center" sx={{ paddingTop: 4 }}>
        <FlatButton
          label="SUBMIT"
          onClick={submitOrderQuantities}
          disableControlsStatus={sizeQuantityList.every(row => !row.operationUniqueCode)}
        />
      </Box>

      <Dialog open={emptyRowDialog} onClose={handleEmptyRowDialogClose}>
        <DialogTitle>Empty Row Error</DialogTitle>
        <DialogContent>
          <Typography variant="body2">
            Empty rows cannot be submitted. Please fill in all required fields.
          </Typography>
        </DialogContent>
      </Dialog>

      <Dialog open={deleteConfirmDialog} onClose={handleDeleteCancel}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography variant="body2" style={{ width: '550px' }}>
            Are you sure you want to delete this row?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} color="error">Delete</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default WorkFlow