import React, { useState, useEffect } from 'react'
import { TextField, Typography, Box, TableContainer, Paper, Table, TableCell, TableHead, TableBody, TableRow, IconButton, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { nanoid } from 'nanoid'
import { AddButton, FlatButton } from '../../UI/Components'
import DeleteIcon from '@mui/icons-material/Delete'
import { BundlePartsDetails, BundlePart } from '../types'

interface BundlePartsProps {
  bundlePartsDetails: BundlePartsDetails
  submitBundle: (parts: string[], partsBarcodes: string[]) => Promise<void>
}

function BundleParts(props: BundlePartsProps) {
  const { bundlePartsDetails, submitBundle } = props
  const [partsList, setPartsList] = useState<BundlePart[]>([])
  const [error, setError] = useState<string | null>(null)
  const [openDialog, setOpenDialog] = useState(false)  // Dialog open state

  useEffect(() => {
    if (bundlePartsDetails?.partBundleData?.length) {
      const partsWithId = bundlePartsDetails.partBundleData.map((part) => ({
        partsId: nanoid(4),
        partName: part.partBundle,
        partBundle: part.partBundle,
      }))
      setPartsList(partsWithId)
    } else {
      setPartsList([{ partBundle: '', partsId: nanoid(4) }])
    }
  }, [bundlePartsDetails])

  // Get existing parts from the database
  const existingParts = bundlePartsDetails?.partBundleData?.map(part => part.partBundle.toLowerCase()) || []

  const handlePartChange = (value: string, id: string) => {
    const trimmedValue = value.trim()

    setPartsList((prev) =>
      prev.map((part) =>
        part.partsId === id ? { ...part, partBundle: trimmedValue, partName: trimmedValue } : part
      )
    )
    setError(null)
  }

  const removePartRow = (id: string) => {
    if (partsList.length <= 1) {
      setError('At least one row must be present')
      return
    }
    setPartsList((prev) => prev.filter((part) => part.partsId !== id))
    setError(null)
  }

  const addPartsRow = () => {
    if (partsList.some((part) => part.partBundle.trim() === '')) {
      setError('Please complete the current row before adding a new one')
      return
    }
    setPartsList((prev) => [...prev, { partBundle: '', partsId: nanoid(4) }])
    setError(null)
  }

  const [loading, setLoading] = useState(false)

  const submitPartsList = async () => {
    if (loading) { return }

    setLoading(true)
    const trimmedParts = partsList.map((part) => part.partBundle.trim())
    const uniqueParts = [...new Set(trimmedParts)].filter(Boolean)

    if (uniqueParts.length === 0) {
      setError('At least one valid part name is required')
      setLoading(false)
      return
    }

    if (uniqueParts.length !== trimmedParts.length) {
      // setError('Duplicate part names are not allowed')
      setLoading(false)
      setOpenDialog(true)  // Open dialog on error
      return
    }

    // Filter out parts that already exist in the database
    const newParts = uniqueParts.filter(part => !existingParts.includes(part.toLowerCase()))

    if (newParts.length === 0) {
      setError('No new parts to submit, all parts already exist')
      setLoading(false)
      return
    }

    const partsBarcodes = newParts.map(() => nanoid(10))
    try {
      await submitBundle(newParts, partsBarcodes)
      setPartsList([{ partBundle: '', partsId: nanoid(4) }])
      setError(null)
    } catch (err) {
      console.error('Error while submitting bundle parts:', err)
      setError('Failed to submit parts list. Please try again.')
    } finally {
      setLoading(false)
    }
  }

  const handleDialogClose = () => {
    setOpenDialog(false)
  }

  return (
    <>
      {error && (
        <Box sx={{ color: 'red', textAlign: 'center', marginBottom: 2 }}>{error}</Box>
      )}
      <Box sx={{ paddingTop: 2 }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow sx={{
                backgroundColor: 'alpha.lightest'
              }}
              >
                <TableCell>
                  <Typography variant="subtitle1">Bundle Part Name</Typography>
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {partsList.map((part) => (
                <TableRow key={part.partsId}>
                  <TableCell>
                    <TextField
                      type="text"
                      variant="outlined"
                      size="small"
                      value={part.partBundle}
                      onChange={(e) => handlePartChange(e.target.value, part.partsId)}
                      placeholder="Enter part name"
                      sx={{ width: '80%' }}
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="delete"
                      size="large"
                      onClick={() => removePartRow(part.partsId)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box display="flex" justifyContent="left" alignItems="center" sx={{ paddingTop: 3 }}>
        <AddButton label="ADD ROW" onClick={addPartsRow} />
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center" sx={{ paddingTop: 4 }}>
        <FlatButton label="SUBMIT" onClick={submitPartsList} disableControlsStatus={false} />
      </Box>

      {/* Dialog for error */}
      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>Duplicate Part Error</DialogTitle>
        <DialogContent>
          <Typography variant="body2">
            Duplicate part names are not allowed. Please ensure each part name is unique.
          </Typography>
        </DialogContent>
        {/* <DialogActions>
          <FlatButton label="OK" onClick={handleDialogClose} />
        </DialogActions> */}
      </Dialog>
    </>
  )
}

export default BundleParts

