import React, { useEffect, useState } from 'react'
import { format } from 'date-fns'
import { Box, Grid } from '@mui/material'
import { LocationDropdownMenu } from './components'
import { getLocationList, getBatchesList, getReportsAnalytics } from './api/apiFunctions'
import useAuthenticationToken from '../Services/Authentication/useAuthenticationToken'
import { locationDetailsType, ReportOperatorAnalyticsData } from './types'
import { DatePicker, SnackBarMessage } from '../UI/Components'
import { dateRangeType } from '../UI/types'
import globalEnums from '../UI/enum'
import { sortAlphaNumericArrayList } from '../utils'
import SewingDateRangeTable from './components/ReportTable'

interface SewingDashboardProps {
  componentHeader: (headerName: string) => void
}

enum DropdownMenuValues {
  batch = 'Select Batch'
}

const formatDateToString = (date: Date | undefined): string => {
  if (!date) {
    return ''
  }
  return format(date, 'yyyyMMdd')
}

const SewingDashboard = (props: SewingDashboardProps) => {
  useAuthenticationToken()
  props.componentHeader('OPERATOR REPORT')

  const [location, setLocation] = useState<string>('')
  const [efficiencyData, setEfficiencyData] = useState<ReportOperatorAnalyticsData[]>([])
  const [searchResults, setSearchResults] = useState<ReportOperatorAnalyticsData[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [dateRange, setDateRange] = useState<dateRangeType>()
  const [locationListResults, setLocationListResults] = useState<locationDetailsType[]>([])
  const [errorMessage, setErrorMessage] = useState('')
  const [snackBarOpen, setSnackBarOpen] = useState(false)
  const [selectedBatch, setSelectedBatch] = useState<string>(DropdownMenuValues.batch)
  const [batchesListResults, setBatchesListResults] = useState<string[]>([])

  useEffect(() => {
    fetchLocationList()
  }, [])

  // Modified to fetch data when location changes
  useEffect(() => {
    if (location && dateRange?.startDate && dateRange?.endDate) {
      const formattedStartDate = formatDateToString(dateRange.startDate)
      const formattedEndDate = formatDateToString(dateRange.endDate)
      // Fetch all data for the location initially
      fetchEfficiencyData('', formattedStartDate, formattedEndDate, '', '')
      // Also fetch batches for the dropdown
      fetchBatchesForLocation(location)
    }
  }, [location, dateRange])

  // Modified to filter data when batch is selected
  useEffect(() => {
    if (selectedBatch !== DropdownMenuValues.batch) {
      // Filter the existing data by batch
      const filteredData = efficiencyData.filter(item => item.batch === selectedBatch)
      setSearchResults(filteredData)
    } else {
      // If no batch is selected, show all data
      setSearchResults(efficiencyData)
    }
  }, [selectedBatch, efficiencyData])

  const fetchLocationList = async () => {
    try {
      setIsLoading(true)
      const locationsList = await getLocationList()
      setLocationListResults(locationsList)
    } catch (err) {
      setErrorMessage(err instanceof Error ? err.message : 'Error fetching locations')
      setSnackBarOpen(true)
    } finally {
      setIsLoading(false)
    }
  }

  const fetchBatchesForLocation = async (location: string) => {
    try {
      const locationCode = getLocationCodeBasedOnLocationName(location)
      const batches = await getBatchesList(locationCode)
      setBatchesListResults(batches)
      setSelectedBatch(DropdownMenuValues.batch) // Reset batch selection when location changes
    } catch (err) {
      setErrorMessage(err instanceof Error ? err.message : 'Error fetching batches')
      setSnackBarOpen(true)
    }
  }

  const fetchEfficiencyData = async (batch: string, startDate: string, endDate: string, operatorToken: string, ocNumber: string) => {
    try {
      const locationCode = getLocationCodeBasedOnLocationName(location)
      const data = await getReportsAnalytics(locationCode, batch, startDate, endDate, operatorToken, ocNumber)
      setEfficiencyData(data)
      setSearchResults(data) // Show all data initially
    } catch (err) {
      setErrorMessage(err instanceof Error ? err.message : 'Error fetching efficiency data')
      setSnackBarOpen(true)
    }
  }

  const getLocationCodeBasedOnLocationName = (locationName: string) => {
    const locationObject = locationListResults.find((loc) => loc.locationName === locationName)
    return locationObject?.location || ''
  }

  const handleDateRangeChange = (value: dateRangeType) => {
    setDateRange(value)
  }

  const handleSnackBarClose = () => {
    setSnackBarOpen(false)
  }

  const batchesDropdownList = () => {
    let batches: string[] = batchesListResults.length > 0 ? [...batchesListResults] : []
    batches = sortAlphaNumericArrayList(batches)
    batches.unshift(DropdownMenuValues.batch)
    return batches
  }

  const locationDropdownList = () => {
    const locations = locationListResults
      .filter((loc) => loc.locationType === globalEnums.production)
      .map((loc) => loc.locationName)

    return ['Select Location', ...sortAlphaNumericArrayList(locations)]
  }

  const handleSearchResults = () => {
    setSearchResults([])
  }

  const isSelectionsComplete = Boolean(
    location &&
    dateRange?.startDate &&
    dateRange?.endDate
  )

  return (
    <React.Fragment>
      <SnackBarMessage
        message={errorMessage}
        successStatus={false}
        open={snackBarOpen}
        onClose={handleSnackBarClose}
      />
      <Grid container sx={{ height: '100vh' }}>
        <Grid item xs={12} sx={{ padding: 0 }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', paddingBottom: 1 }}>
            <Box sx={{ marginRight: 3 }}>
              <DatePicker
                onChange={handleDateRangeChange}
                defaultDateRange={(value: dateRangeType) => setDateRange(value)}
                direction={'horizontal'}
              />
            </Box>
            <Box sx={{ marginRight: 3 }}>
              <LocationDropdownMenu
                onChange={setLocation}
                menuValues={locationDropdownList()}
                buttonText={location || 'Select Location'}
              />
            </Box>
            <Box sx={{ marginRight: 3 }}>
              <LocationDropdownMenu
                onChange={setSelectedBatch}
                menuValues={batchesDropdownList()}
                buttonText={selectedBatch || 'Select Batch'}
              />
            </Box>
          </Box>

          {isSelectionsComplete && dateRange?.startDate && dateRange?.endDate ? (
            <SewingDateRangeTable
              data={searchResults}
              location={getLocationCodeBasedOnLocationName(location)}
              batch={selectedBatch}
              startDate={formatDateToString(dateRange.startDate)}
              endDate={formatDateToString(dateRange.endDate)}
              onChange={handleSearchResults}
            />
          ) : (
            <Box sx={{ bgcolor: 'primary.card', p: 2, textAlign: 'center' }}>
              Please select location and date range to view data
            </Box>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default SewingDashboard