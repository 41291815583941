import React, { useState, useEffect } from 'react'
import {
  Typography, Box, TableContainer, Paper, Table, TableCell, TableHead,
  TableBody, TableRow, FormGroup, Checkbox, Stack, TextField, Skeleton, Grid
} from '@mui/material'
import { FlatButton, SnackBarMessage, SubmitDialog } from '../../UI/Components'
import StringValues from '../../Providers/StringValues'
import useAuthenticationToken from '../../Services/Authentication/useAuthenticationToken'
import { getALLRackDetails, getRackDetailsType } from '../types'
import { getAllRackDetails, getRackDetailsbyLocation } from '../api/apiFunctions'
import { AxiosError } from 'axios'
const ZebraBrowserPrintWrapper = require('zebra-browser-print-wrapper')

const ReprintRackQrLabel = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [snackBarOpen, setSnackBarOpen] = useState(false)
  const [rackLocation, setRackLocation] = useState('')
  const [rackBarcodeDetails, setRackBarcodeDetails] = useState<getRackDetailsType[]>([])
  const [rackAllDetails, setRackAllDetails] = useState<getALLRackDetails[]>([])
  const [selectedRackDetails, setSelectedRackDetails] = useState<getRackDetailsType[]>([])
  const [dialogOpen, setDialogOpen] = useState(false)
  const [dialogMessage, setDialogMessage] = useState('')

  useAuthenticationToken()

  useEffect(() => {
    fetchAllRackDetails()
  }, [])

  const fetchAllRackDetails = async () => {
    setIsLoading(true)
    try {
      const rackDetails = await getAllRackDetails()
      setRackAllDetails(rackDetails)
      setIsError(false)
    } catch (err) {
      handleError(err)
    }
    setIsLoading(false)
  }

  const fetchRackDetailsByLocation = async () => {
    if (!rackLocation.trim()) { return }
    setIsLoading(true)
    try {
      const rackDetails = await getRackDetailsbyLocation(rackLocation)
      setRackBarcodeDetails(rackDetails)
      setSelectedRackDetails(rackDetails.map(obj => ({ ...obj, isSelected: false })))
      setIsError(false)
    } catch (err) {
      handleError(err)
    }
    setIsLoading(false)
  }

  const handleError = (err: unknown) => {
    if (err instanceof AxiosError || err instanceof Error) {
      setSnackBarOpen(true)
      setIsError(true)
      setErrorMessage(err.message)
    }
  }

  const handleSelectAll = () => {
    const allSelected = selectedRackDetails.every(barcode => barcode.isSelected)
    setSelectedRackDetails(selectedRackDetails.map(barcode => ({ ...barcode, isSelected: !allSelected })))
  }

  const handleCheckboxChange = (rackBarcode: string) => {
    setSelectedRackDetails(
      selectedRackDetails.map(barcode =>
        barcode.rackBarcode === rackBarcode ? { ...barcode, isSelected: !barcode.isSelected } : barcode
      )
    )
  }

  const printBarcodeConfirmation = () => {
    setDialogMessage('Are you sure you want to print selected barcodes?')
    setDialogOpen(true)
  }

  const handleSubmitDialogClose = async (value: boolean) => {
    setDialogOpen(false)
    if (value) { await printSelectedBarcodes() }
  }

  const printSelectedBarcodes = async () => {
    const selectedBarcodes = selectedRackDetails.filter(barcode => barcode.isSelected)
    if (selectedBarcodes.length === 0) {
      handleSnackBarMessage('Please select at least one barcode to print.')
      return
    }

    handleLoading(true)
    const browserPrint = new ZebraBrowserPrintWrapper.default()

    try {
      const printers = await browserPrint.getAvailablePrinters()
      if (!printers.length) { throw new Error(StringValues.zebraPrinterNotFoundMessage) }

      const printer = printers[0]
      browserPrint.setPrinter(printer)

      for (const { rackBarcode, rackLocation } of selectedBarcodes) {
        if (rackLocation === 'SAMPLING') {
          const zplCode = `^XA
  ^MMT
  ^PW812
  ^LL424
  ^LS0

  ^FT355,278^A0N,3,5^FH\\^CI28^FDRACK^FS^CI27
  ^FT385,314^A0N,62,61^FH\\^CI28^FD${rackLocation}^FS^CI27
  ^FT378,260^A0N,135,134^FH\\^CI28^FD${rackBarcode}^FS^CI27

  ^FT45,350^BQN,2,6^FH\\^FDLA,location - ${rackLocation} Rack - ${rackBarcode}^FS
  ^PQ1,0,1,Y
^XZ
`
          await browserPrint.print(zplCode)
        }
        else {
          const zplCode = `^XA
        ^MMT
        ^PW812
        ^LL424
        ^LS0
        ^FT355,10^A0N,3,5^FH\\^CI28^FDRACK^FS^CI27
        ^FT385,114^A0N,62,61^FH\\^CI28^FD${rackLocation}^FS^CI27
        ^FT378,278^A0N,135,134^FH\\^CI28^FD${rackBarcode}^FS^CI27
        ^FT45,360^BQN,2,10^FH\\^FDLA,location - ${rackLocation} Rack - ${rackBarcode}^FS
        ^PQ1,0,1,Y
        ^XZ`
          await browserPrint.print(zplCode)
        }



      }
      handleLoading(false)
    } catch (error) {
      handleSnackBarMessage(error instanceof Error ? error.message : 'Unknown error occurred')
      handleLoading(false)
    }
  }

  const handleSnackBarMessage = (message: string) => {
    setErrorMessage(message)
    setSnackBarOpen(true)
  }

  const handleSnackBarClose = (value: boolean) => {
    setSnackBarOpen(value)
  }

  const handleLoading = (loadingStatus: boolean) => {
    setIsLoading(loadingStatus)
  }

  return (
    <React.Fragment>
      <SnackBarMessage message={errorMessage} successStatus={!isError} open={snackBarOpen} onClose={handleSnackBarClose} />
      <Box sx={{ padding: 2 }}>
        <Stack spacing={3} direction="row">
          <TextField
            type="text"
            placeholder="Enter Location"
            variant="outlined"
            size="small"
            value={rackLocation}
            onChange={e => setRackLocation(e.target.value)}
          />
          <FlatButton label="SEARCH" onClick={fetchRackDetailsByLocation} disableControlsStatus={isLoading} />
        </Stack>
        <Grid container sx={{ height: '100vh', paddingTop: 2 }}>
          <Grid item xs={12}>
            {isLoading ? (
              <Skeleton variant="rectangular" sx={{ minWidth: '100%', height: '100%', bgcolor: 'primary.pane' }} />
            ) : (
              <Box sx={{ paddingTop: 2 }}>
                <Stack direction="row" justifyContent="space-between">
                  <FlatButton label="PRINT" onClick={printBarcodeConfirmation} disableControlsStatus={false} />
                </Stack>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <FormGroup>
                            <Checkbox
                              checked={selectedRackDetails.every(barcode => barcode.isSelected)}
                              onChange={handleSelectAll}
                            />
                          </FormGroup>
                        </TableCell>
                        <TableCell>RACK NUMBER</TableCell>
                        <TableCell>LOCATION</TableCell>
                        <TableCell>ACTION</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {selectedRackDetails.map(({ rackBarcode, rackLocation, isSelected }, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <Checkbox
                              checked={isSelected}
                              onChange={() => handleCheckboxChange(rackBarcode)}
                            />
                          </TableCell>
                          <TableCell>{rackBarcode}</TableCell>
                          <TableCell>{rackLocation}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
      <SubmitDialog onClose={handleSubmitDialogClose} open={dialogOpen} submitStatus={false} dialogMessage={dialogMessage} />
    </React.Fragment>
  )
}

export default ReprintRackQrLabel

