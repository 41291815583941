import { apiClient } from "../../Services/Networking/apiClient"
import { ManageOrdersDetails, TechPackProductDetails, OrderDetails, sizeQuantities, PartsDetails, ManagementProgress, BundlePartsDetails, Batch, WorkFlowDetails } from "../types"

export const getManageOrdersBasedOnOcNumber = async (ocNumber: string): Promise<ManageOrdersDetails> => {
  const response = await apiClient({
    method: 'get',
    url: `fabcut/cutting/orders/${ocNumber}`
  })
  return response as unknown as ManageOrdersDetails
}

export const getTechPackProductDetailsBasedOnOcNumber = async (ocNumber: string): Promise<TechPackProductDetails[]> => {
  const response = await apiClient({
    method: 'get',
    url: `fabstitch/ocNumber/${ocNumber}/products`
  })
  return response as unknown as TechPackProductDetails[]
}

export const getOrderDetails = async (location: string, ocNumber: string, itemCode: string, fitType: string): Promise<OrderDetails> => {
  const response = await apiClient({
    method: 'get',
    url: `fabcut/cutting/orders/orderDetails`,
    params: { location, ocNumber, itemCode, fitType }
  })
  return response as unknown as OrderDetails
}

export const postOrderSizeAndQuantities = async (OC: string, location: string, itemCode: string, itemDescription: string, fitType: string, approvedConsumption: number, sizeQuantities: sizeQuantities[]): Promise<Boolean> => {
  const response = await apiClient({
    method: 'post',
    url: `fabcut/cutting/orders/${OC}`,
    data: {
      location,
      itemCode,
      itemDescription,
      fitType,
      approvedConsumption,
      sizeQuantities
    }
  })
  return response as unknown as Boolean
}

export const getPartsDetailsBasedOnOcAndLocation = async (location: string, ocNumber: string): Promise<PartsDetails> => {
  const response = await apiClient({
    method: 'get',
    url: `fabcut/cutting/parts/${ocNumber}`,
    params: { location }
  })
  return response as unknown as PartsDetails
}

export const postPartsDetails = async (ocNumber: string, location: string, style: string, parts: string[]): Promise<Boolean> => {
  const response = await apiClient({
    method: 'post',
    url: `fabcut/cutting/parts/${ocNumber}`,
    data: {
      location,
      style,
      parts
    }
  })
  return response as unknown as Boolean
}

export const getTechPackBasedOnOcLocationAndProduct = async (location: string, ocNumber: string, productCode: string): Promise<string> => {
  const response = await apiClient({
    method: 'get',
    url: `fabstitch/defect/product/${productCode}/techPack`,
    params: { location, ocNumber }
  })
  return response as unknown as string
}

export const postTechPack = async (location: string, ocNumber: string, productCode: string, productDescription: string, productTechPack: string): Promise<Boolean> => {
  const response = await apiClient({
    method: 'post',
    url: `fabstitch/defect/product/${productCode}/techPack`,
    data: {
      ocNumber,
      location,
      productDescription,
      productTechPack
    }
  })
  return response as unknown as Boolean
}

export const getManagementProgressBasedOnOcAndLocation = async (location: string, ocNumber: string): Promise<ManagementProgress> => {
  const response = await apiClient({
    method: 'get',
    url: `fabcut/cutting/orders/location/${location}/oc/${ocNumber}/orderProgressDetails`
  })
  return response as unknown as ManagementProgress
}

{/*Bundle parts start */ }
export const getWorkFlowDetails = async (location: string, ocNumber: string, productCode: string): Promise<BundlePartsDetails> => {
  // console.log('Request details:', location, ocNumber, productCode)
  const response = await apiClient({
    method: 'get',
    url: `/fabcut/cutting/parts/${ocNumber}/${productCode}/${location}/partsBundle`,
  })
  // console.log('Response:', response)
  return response as unknown as BundlePartsDetails
}
{/*Bundle parts end */ }


{/*Bundle parts start */ }
export const postPartsBundle = async (ocNo: string, partsBundle: string[], partsBundleBarcode: string[], productCode: string, location: string): Promise<Boolean> => {
  const response = await apiClient({
    method: 'post',
    url: `fabcut/cutting/parts/${ocNo}/partsBundle`,
    data: { partsBundle, partsBundleBarcode, productCode, location },
  })
  console.log("Response............1234", response)
  return response as unknown as Boolean
}


{/*Bundle parts end */ }

{/* Batch API */ }
export const getBatchesAssociatedWithLocation = async (location: string): Promise<Batch[]> => {
  const response = await apiClient({
    method: 'get',
    url: `/fabstitch/location/${location}/batches`,
    // params: { location, searchTerm }
  })
  return response as unknown as Batch[]
}

{/* Batch Post API */ }


// work flow post API

export const postWorkFlowBundleOperation = async (ocNumber: string, location: string, productCode: string, batch: string,
  operationUniqueCode: string, partBundle: string, partOperation: string, partSam: string, operator: string, operationUserToken: string): Promise<Boolean> => {
  console.log('data...', ocNumber, location, productCode, batch, operationUniqueCode, partBundle, partOperation, partSam, operationUserToken, operator)
  const response = await apiClient({
    method: 'post',
    url: `/fabstitch/product/label/PartsBundleOperation`,
    data: {
      ocNumber, location, productCode, batch, operationUniqueCode, partBundle, partOperation, partSam,
      operator, operationUserToken,
    },

  })
  console.log('Response............', response)
  return response as unknown as Boolean
}




{/* Work flow API start */ }

export const getWorkFlowDetail = async (location: string, ocNumber: string, productCode: string, batch: string): Promise<WorkFlowDetails> => {
  console.log('Response........8', location, batch, productCode, ocNumber)
  const response = await apiClient({
    method: 'get',
    url: `/fabstitch/product/label/${ocNumber}/${productCode}/${location}/${batch}/partsBundleWorkflow`,
  })
  console.log('Response........ wo', response)
  return response as unknown as WorkFlowDetails
}





{/* Work flow API end */ }


{/* work flow delete api start */ }

export const deletePartsBundleOperation = async (operationUniqueCode: string): Promise<boolean> => {
  try {
    console.log(`/fabstitch/product/label/PartsBundleOperation/${operationUniqueCode}`, operationUniqueCode)
    const response = await apiClient({
      method: 'delete',
      url: `/fabstitch/product/label/PartsBundleOperation/${operationUniqueCode}`,
      data: {
        operationUniqueCode
      }
    })

    console.log("Response.....................", response)
    return response.status === 200 // Assuming a successful response returns 200
  } catch (error) {
    console.error("Error deleting parts bundle operation:", error)
    return false // Return false on error
  }
}

{/* work flow delete api end */ }