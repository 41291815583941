import { apiClient } from "../../Services/Networking/apiClient"
import { PartsbarcodeDetailsType, PostProductLabelType, locationDetailsType, getProductLabelDetailsType, SageQty, QRloading, getBundlePartsDetailsType, getTransactionBundleNoType, postPartsBundleLabelType, PostPartBundleTransformedLabelData, PostPartBundleTransformedData } from "../types"


export const getPartBarcodeDetails = async (partbarcode: string): Promise<PartsbarcodeDetailsType> => {
  const response = await apiClient({
    method: 'get',
    url: `/fabstitch/partbarcode/${partbarcode}/detail`
  })
  return response as unknown as PartsbarcodeDetailsType
}

export const postBarcodeLabel = async (batchNumber: string, data: PostProductLabelType): Promise<Boolean> => {
  const response = await apiClient({
    method: 'post',
    url: `/fabstitch/product/label/batchNumber/${batchNumber}`,
    data: data
  })
  return response as unknown as Boolean
}

export const getLocationList = async (): Promise<locationDetailsType[]> => {
  const response = await apiClient({
    method: 'get',
    url: `/service/locations`
  })
  return response as unknown as locationDetailsType[]
}

export const getBatchesAssociatedWithLocation = async (location: string): Promise<string[]> => {
  const response = await apiClient({
    method: 'get',
    url: `/fabstitch/location/${location}/batches`
  })
  return response as unknown as string[]
}

export const getBuyerPoBasedOnOcNumber = async (ocNumber: string): Promise<string[]> => {
  const response = await apiClient({
    method: 'get',
    url: `fabstitch/product/label/${ocNumber}/buyerPo`
  })
  return response as unknown as string[]
}

export const getBuyerCountryBasedOnPoNumber = async (ocNumber: string, poNumber?: string): Promise<string[]> => {
  // let Data = ocNumber ? {ocNumber} : {PoNumber: poNumber}
  const response = await apiClient({
    method: 'get',
    url: `fabstitch/product/label/${ocNumber}/${poNumber}/countries`
  })
  return response as unknown as string[]
}


export const getProductLabelDetails = async (bundleBarcode: string): Promise<getProductLabelDetailsType[]> => {
  const response = await apiClient({
    method: 'get',
    url: `/fabstitch/product/bundle/${bundleBarcode}`
  })
  return response as unknown as getProductLabelDetailsType[]
}

export const getQuantitybasedOnSize = async (ocNumber: string, poNumber?: string, Country?: string, Color?: string, Size?: String): Promise<SageQty> => {
  // let Data = ocNumber ? {ocNumber} : {PoNumber: poNumber}
  const response = await apiClient({
    method: 'get',
    url: `fabstitch/product/label/${ocNumber}/${poNumber}/${Country}/${Color}/${Size}/Qty`
  })
  return response as unknown as SageQty
}

export const getloadingQR = async (startDate: string, endDate: string) => {
  const response = await apiClient({
    method: 'get',
    url: `fabstitch/loadingqr/${startDate}/${endDate}`,
  })
  return response as unknown as QRloading[]
}

// export const getPartsBundle = async (ocNumber: string, location: string, batch: string, colorCode: string, size: string, poNumber: string, country: string, productCode: string, transactionBundle: string): Promise<getPartsBundleType[]> => {
//   console.log("params", location, batch, colorCode, size, poNumber, country, productCode, transactionBundle)
//   const response = await apiClient({
//     method: 'get',
//     url: `fabstitch/product/label/${ocNumber}/partsBundle`,
//     params: { location, batch, colorCode, size, poNumber, country, productCode, transactionBundle }
//   })
//   console.log("response psrt bundle", response)
//   console.log("Raw API Response:", response.data)
//   // return response.data;
//   return response as unknown as getPartsBundleType[]
// }



export const getTransactionBundleNo = async (labelQrCode: string): Promise<getTransactionBundleNoType[]> => {
  // let Data = ocNumber ? {ocNumber} : {PoNumber: poNumber}
  const response = await apiClient({
    method: 'get',
    url: `fabstitch/product/label/${labelQrCode}/transactionBundleNo`,
  })
  console.log("getTransactionBundleNo", response)
  return response as unknown as getTransactionBundleNoType[]
}

export const getPartsBundle = async (transactionBundleNo: string): Promise<getBundlePartsDetailsType[]> => {
  console.log("params", transactionBundleNo)
  const response = await apiClient({
    method: 'get',
    url: `fabstitch/product/label/${transactionBundleNo}/partsBundleDetails`
  })
  console.log("getPartsBundle", response)

  return response as unknown as getBundlePartsDetailsType[]
}


export const postPartsBundleBarcodeLabel = async (transactionBundleNo: string, dataPartBundle: PostPartBundleTransformedData): Promise<Boolean> => {
  console.log("postPartsBundleBarcodeLabel", dataPartBundle)
  const response = await apiClient({
    method: 'post',
    url: `fabstitch/product/label/${transactionBundleNo}/partsBundleDetails`,
    data: dataPartBundle
  })
  console.log("postPartsBundleBarcodeLabel", response)
  return response as unknown as Boolean
}
