import { apiClient } from "../../Services/Networking/apiClient"
import { locationDetailsType, ReportOperatorAnalyticsData } from "../types"

export const getLocationList = async (): Promise<locationDetailsType[]> => {
  const response = await apiClient({
    method: 'get',
    url: `/service/locations`
  })
  console.log("Response", response)
  return response as unknown as locationDetailsType[]
}



export const getBatchesList = async (location: string): Promise<string[]> => {
  const response = await apiClient({
    method: 'get',
    url: `/fabstitch/location/${location}/batches`
  })
  console.log("Response", response)
  return response as unknown as string[]
}



export const getReportsAnalytics = async (location: string, batch: string, startDate: string, endDate: string, operatorToken: string, ocNumber: string) => {
  console.log(`/fabstitch/product/label/${startDate}/${endDate}/workflowOperationAnalytics?operatorToken=${operatorToken}&location=${location}&batch=${batch}&ocNumber=${ocNumber}`,
    location, batch, startDate, endDate, operatorToken, ocNumber)

  // Construct the params object based on the query parameters required
  const params: { operatorToken?: string; location?: string; batch?: string; ocNumber?: string } = {}

  if (operatorToken) {
    params.operatorToken = operatorToken
  }
  if (location) {
    params.location = location
  }
  if (batch) {
    params.batch = batch
  }
  if (ocNumber) {
    params.ocNumber = ocNumber
  }

  try {
    const response = await apiClient({
      method: "get",
      url: `/fabstitch/product/label/${startDate}/${endDate}/workflowOperationAnalytics`,
      params,
    })
    console.log("Response", response)
    return response as unknown as ReportOperatorAnalyticsData[]
  } catch (error) {
    console.error("Error fetching analytics data", error)
    throw error
  }
}





