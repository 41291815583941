import React, { useState, useEffect } from 'react'
import {
  Paper, Table, TableBody, TableCell, TableContainer, TableHead,
  TableRow, Box, Button, Menu, MenuItem, TablePagination
} from '@mui/material'
import { KeyboardArrowDown } from '@mui/icons-material'
import { SearchBar, FlatButton } from '../../UI/Components'
import { ReportOperatorAnalyticsData } from '../types'
import { getReportsAnalytics } from '../api/apiFunctions'
import * as XLSX from 'xlsx' // Import xlsx library

enum DropdownMenuValues {
  searchOc = 'ENTER OC NUMBER',
  operatorToken = 'ENTER OPERATOR TOKEN NUMBER'
}

interface SewingDateRangeTableProps {
  data: ReportOperatorAnalyticsData[]
  location: string
  batch: string
  startDate: string
  endDate: string
  onChange?: (searchType: DropdownMenuValues) => void
}

interface SearchBarChangeEvent extends React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> {
  target: HTMLInputElement | HTMLTextAreaElement
}

const SewingDateRangeTable: React.FC<SewingDateRangeTableProps> = ({
  data,
  location,
  batch,
  startDate,
  endDate,
  onChange
}) => {
  const [buttonText, setButtonText] = useState<DropdownMenuValues>(DropdownMenuValues.searchOc)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [isError, setIsError] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [filteredData, setFilteredData] = useState<ReportOperatorAnalyticsData[]>([])

  // Pagination state
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)

  const open = Boolean(anchorEl)

  useEffect(() => {
    setFilteredData(data)
  }, [data])

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleMenuItemClick = (e: React.MouseEvent<HTMLElement>) => {
    const newText = e.currentTarget.textContent as DropdownMenuValues
    if (newText !== buttonText) {
      setButtonText(newText)
      setSearchTerm('')
      setFilteredData(data)
      onChange?.(newText)
    }
    handleClose()
  }

  const handleSearchBarChange = (event: SearchBarChangeEvent) => {
    setSearchTerm(event.target.value)
  }

  const handleSearchBarButtonClick = async () => {
    if (!searchTerm.trim()) {
      setIsError(true)
      setErrorMessage('Please enter a search term')
      return
    }

    try {
      setIsError(false)
      setErrorMessage('')
      setFilteredData([])

      const searchResults = await getReportsAnalytics(
        location,
        batch,
        startDate,
        endDate,
        buttonText === DropdownMenuValues.operatorToken ? searchTerm : '',
        buttonText === DropdownMenuValues.searchOc ? searchTerm : ''
      )

      if (!searchResults || searchResults.length === 0) {
        setIsError(true)
        setErrorMessage('No results found')
      } else {
        setFilteredData(searchResults)
      }
    } catch (error) {
      console.error('API Error:', error)
      setIsError(true)
      setErrorMessage('An error occurred while fetching data')
    }
  }

  // Handle page change
  // const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
  //   setPage(newPage)
  // }

  // Handle rows per page change
  // const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setRowsPerPage(parseInt(event.target.value, 10))
  //   setPage(0) // Reset to first page when rows per page change
  // }

  // Function to export data to Excel
  const handleDownloadExcel = () => {
    const ws = XLSX.utils.json_to_sheet(filteredData)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Report Data')
    XLSX.writeFile(wb, 'report_data.xlsx') // Download the Excel file
  }

  return (
    <TableContainer component={Paper}>
      <Box
        component="span"
        m={1}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ bgcolor: 'primary.card' }}
      >
        <Box padding={2}>
          <Button
            id="dropdown-menu-button"
            aria-controls={open ? 'dropdown-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            sx={{
              fontFamily: 'button.medium',
              border: '1px solid black'
            }}
            endIcon={<KeyboardArrowDown />}
          >
            {buttonText}
          </Button>
          <Menu
            id="dropdown-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'dropdown-menu-button'
            }}
          >
            <MenuItem onClick={handleMenuItemClick}>{DropdownMenuValues.searchOc}</MenuItem>
            <MenuItem onClick={handleMenuItemClick}>{DropdownMenuValues.operatorToken}</MenuItem>
          </Menu>

          <SearchBar
            value={searchTerm}
            onChange={handleSearchBarChange}
            onButtonClick={handleSearchBarButtonClick}
            error={isError}
            errorMessage={errorMessage}
            inputLabel="Search"
            width="auto"
            marginLeft={10}
          />
        </Box>
        {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
        <FlatButton
          label="DOWNLOAD"
          onClick={handleDownloadExcel} // Call the function to download Excel
          disableControlsStatus={false}
        />
      </Box>
      <Table>
        <TableHead>
          <TableRow sx={{ backgroundColor: 'alpha.lightest' }}>
            <TableCell align="center" sx={{ fontWeight: 'bold' }}>SNO</TableCell>
            <TableCell align="center" sx={{ fontWeight: 'bold' }}>OPERATOR TOKEN</TableCell>
            <TableCell align="center" sx={{ fontWeight: 'bold' }}>OPERATOR</TableCell>
            <TableCell align="center" sx={{ fontWeight: 'bold' }}>OPERATION</TableCell>
            <TableCell align="center" sx={{ fontWeight: 'bold' }}>LINE</TableCell>
            <TableCell align="center" sx={{ fontWeight: 'bold' }}>OC</TableCell>
            <TableCell align="center" sx={{ fontWeight: 'bold' }}>DATE</TableCell>
            <TableCell align="center" sx={{ fontWeight: 'bold' }}>EFFICIENCY</TableCell>
            <TableCell align="center" sx={{ fontWeight: 'bold' }}>MINS PRODUCED</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredData.length === 0 ? (
            <TableRow>
              <TableCell colSpan={8} align="center">
                {isError ? errorMessage : 'No data available'}
              </TableCell>
            </TableRow>
          ) : (
            // filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row: ReportOperatorAnalyticsData, index: number) => (
            filteredData.map((row: ReportOperatorAnalyticsData, index: number) => (
              <TableRow key={index}>
                <TableCell align="center">{index + 1}</TableCell>
                <TableCell align="center">{row.operationUserToken}</TableCell>
                <TableCell align="center">{row.operatorName}</TableCell>
                <TableCell align="center">{row.operation}</TableCell>
                <TableCell align="center">{row.batch}</TableCell>
                <TableCell align="center">{row.ocNumber}</TableCell>
                <TableCell align="center">{row.operationDates}</TableCell>
                <TableCell align="center">{row.averageEfficiency}</TableCell>
                <TableCell align="center">{row.producedMin}</TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default SewingDateRangeTable

